<template>
  <el-row class="goodsInfoBox">
    <div v-if="goodsInfo.isCan===0" class="abBg">不可售后</div>
    <div class="item" >
      <el-col :span="12"><ShopImg :msg="goodsInfo"></ShopImg></el-col>
      <el-col :span="3" class="text-center">{{
        goodsInfo.originalPrice | capitalize
      }}</el-col>
      <el-col :span="3" class="text-center">
        <div class="wraper"  v-if="goodsInfo.orderType==1">
        <!-- <div class="wraper"  v-if="goodsInfo.goodsType==1?false:goodsInfo.goodsType==3?false:true"> -->
          <button type='button' class="reduce text-center"   @click="goodsInfo.isCan!==0 && handleReducer(goodsLineNum)">-</button>
          <input
            type="text"
            v-model="num"
            :max="maxCount"
            :min="0"
            :step="1"
            @input="goodsInfo.isCan!==0  && handleChangValue(goodsLineNum)"
            ref="input"
          />
          <button type='button' class="increase text-center"    @click="goodsInfo.isCan!==0  && handleAdd(goodsLineNum)">+</button>
        </div>
        <div class="wraper"  v-if="goodsInfo.orderType!==1">{{num}}</div>
        <!-- <div class="wraper"  v-if="goodsInfo.goodsType==1?true:goodsInfo.goodsType==3?true:false">{{num}}</div> -->
        <!-- <el-input-number v-model="num" :max="goodsInfo.goodsCount" :min="0" step-strictly></el-input-number> -->
      </el-col>
      <el-col :span="3" class="text-center">{{
        totalPrice | capitalize
      }}</el-col>
       <el-col :span="3" class="text-center total">{{
        goodsInfo.payablePrice | capitalize
      }}</el-col>

    </div>
  </el-row>
</template>
<script>
const ShopImg = () => import("components/common/ShopImg.vue");
export default {
  name: "OrderInfoStem",
  data() {
    return {
      num:1,//初始input商品数量
      maxCount:0   //最大退货数
    };
  },
  created() {
    this.goodsLineNum=this.indexNum
      if(this.goodsInfo.refundCount>-1){
        this.maxCount=Number(this.goodsInfo.goodsCount)-Number(this.goodsInfo.refundCount)
      }else{
        this.maxCount=Number(this.goodsInfo.goodsCount)
      }

      this.num=this.maxCount
    
  },
  props: {
    goodsInfo: {
      type: Object,
    },
    indexNum:{   //点击的商品是第几条
      type:Number
    }
  },
  methods: {
        // 减
        handleReducer(){
            if(this.num<=0){
                this.$message({
                    message: '最小退货数量为0,此时该商品不走售后',
                    type: 'warning'
                });
            }else{
                this.num=this.num-1
            }
            let data={
                refundCount:this.num,
                // indexNum:this.goodsLineNum,
                id:this.goodsInfo.id,
                counts:this.maxCount
            }
             this.$emit("GoodsItemFun",data)
        },
        // 加
        handleAdd(){
          // this.goodsInfo.goodsCount
            if(this.num>=this.maxCount){
                this.$message({
                    message: '最大退货数量为'+this.maxCount+'',
                    type: 'warning'
                });
            }else{
                this.num=this.num+1
            }
            let data={
                refundCount:this.num,
                // indexNum:this.goodsLineNum,
                id:this.goodsInfo.id,
                counts:this.maxCount
            }
             this.$emit("GoodsItemFun",data)
        },
        //改变input
        handleChangValue(){
            if(this.num>=this.maxCount){
                this.$message({
                    message: '最大退货数量为'+this.maxCount+'',
                    type: 'warning'
                });
                this.num=Number(this.maxCount)
            }else if(this.num<=0){
                this.$message({
                    message: '最小退货数量为0,此时该商品不走售后',
                    type: 'warning'
                });
            }else{
                this.num=Number(this.num)
            }

             let data={
                refundCount:Number(this.num),
                // indexNum:this.goodsLineNum,
                id:this.goodsInfo.id,
                counts:this.maxCount
            }
             this.$emit("GoodsItemFun",data)
        }
  },

  components: {
    ShopImg,
  },
  computed: {
    totalPrice() {
      //promotionAmount  优惠金额     payablePrice  应付金额     originalPrice 原价
      let totalPrice = Number(
        this.goodsInfo.originalPrice * this.num
      ).toFixed(2);
      return totalPrice;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.goodsInfoBox{
  position: relative;
  .abBg{
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6f6;
    color: red;
    font-size: 30px;
    font-weight: bold;
    opacity: 0.3;
    z-index: 1;
  }
}
.wraper{
        margin: 0 auto;
    display: block;
    width: 120px;
}
.item {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-left: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  color: #666;
  .total {
    font-weight: bold;
  }
  button {
    width: 16px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    font-size: 15px;
    // border: 1px solid #F0F0F0;
    border: none;
    float: left;
  }

  input {
    display:block;
    float: left;
    width: 70px;
    height: 36px;
    background: #f0f0f0;
    text-align: center;
    margin-left: 4px;
    margin-right: 4px;
    border: none;
    font-size: 14px;
    //  border: 1px solid #F0F0F0;
  }
}
</style>
